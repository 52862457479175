"use client"
import Image from "next/image"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {useEffect, useState} from "react"
import {cn} from "@/lib/utils"

const reviews = [
    {
        rating: 5,
        title: "Quick and effective results",
        body: "I wasn't expecting much, but CreditCaptain came through. They cleaned up some errors on my credit report, and my score jumped by 65 points. No fuss, just results.",
        author: "Mark H.",
        role: "Warehouse Supervisor, FedEx",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2faf4061678aaa9ca9e95_5_555.jpg"
    },
    {
        rating: 5,
        title: "A fresh start",
        body: "Years of financial mistakes followed me like a shadow, and I thought I'd never escape the low credit score spiral. CreditCaptain changed that. I didn't expect to get much from it, but slowly, I saw my score rise as negative items disappeared. It's like a weight lifted off my shoulders.",
        author: "Rebecca L.",
        role: "Administrative Assistant, Verizon",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2fb248726c57ec817e9ce_2222.jpg"
    },
    {
        rating: 5,
        title: "Fast and hassle-free",
        body: "CreditCaptain worked faster than I expected. Score up 40 points, no headaches, no stress. Highly recommend.",
        author: "Carlos M.",
        role: "Delivery Driver, UPS",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2fb3858782c8bcdca4b1c_3_333.jpg"
    },
    {
        rating: 5,
        title: "Systematic improvement",
        body: "I was facing a lot of frustration with my credit report—incorrect late payments, lingering marks, and errors that were dragging my score down. CreditCaptain's AI systematically worked through these issues, and within six weeks, I saw a 75-point improvement. It's the combination of automation and expertise that makes this service stand out.",
        author: "Emily R.",
        role: "Financial Analyst, Bank of America",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2fb0741d470d1b3379a1e_1111.jpg"
    },
    {
        rating: 5,
        title: "Real results",
        body: "I've tried a few credit repair tools before, and most just make big promises with little to show for it. CreditCaptain? It works. In two months, I saw a 60-point boost. Nothing flashy, just solid improvements where it matters.",
        author: "Jason S.",
        role: "Shift Manager, Starbucks",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2fb3a1a54182b52316db9_4_444.jpg"
    },
    {
        rating: 5,
        title: "Finally in the 700 club",
        body: "CreditCaptain turned things around for me. I was stuck in the 600s for what felt like forever, but after a few months, I'm now in the 700 club! The app is easy to use, and you can see real progress without all the confusing jargon.",
        author: "Sarah P.",
        role: "Retail Sales Associate, Best Buy",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2fb84db5257b3c10048b7_5555.jpg"
    },
    {
        rating: 5,
        title: "Let AI handle it",
        body: "Honestly, I've always hated dealing with anything related to credit. But CreditCaptain was different—super easy to use and actually did what they said. My score's up by 50 points, and I didn't have to spend hours on the phone. Just let the AI handle it.",
        author: "Jake T.",
        role: "Store Associate, Target",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2fb108726c57ec817ddb5_2_222.jpg"
    },
    {
        rating: 5,
        title: "Simple and effective",
        body: "Simple, effective, and no hidden tricks. My score went up 45 points in a couple of weeks. I'll take it.",
        author: "Alexis B.",
        role: "Cashier, McDonald's",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2fb70978e2b9a9b3f56ad_4444.jpg"
    },
    {
        rating: 5,
        title: "AI that works",
        body: "I wasn't sure what to expect with CreditCaptain. I've been burned by credit repair promises before. But to my surprise, the AI really does work. My score moved up 55 points in a month. Still keeping an eye on the long-term effects, but so far, so good.",
        author: "Tom K.",
        role: "Driver, Lyft",
        avatarSrc: "https://cdn.prod.website-files.com/66b251a70353a05795df45c3/66f2fb609a1d9178b2a0eb11_1_111.jpg"
    }
]

export default function AuthLayout({children}: {children: React.ReactNode}) {
    const [queryClient] = useState(() => new QueryClient())
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0)
    const [isAnimating, setIsAnimating] = useState(false)
    const currentReview = reviews[currentReviewIndex]
    
    useEffect(() => {
        const timer = setInterval(() => {
            setIsAnimating(true)
            setTimeout(() => {
                setCurrentReviewIndex((prev) => (prev + 1) % reviews.length)
                setIsAnimating(false)
            }, 600)
        }, 4000)

        return () => clearInterval(timer)
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <div className="auth-bg flex min-h-screen flex-col">
                <Image src="/images/auth-pattern.svg" alt="CreditCaptain Auth Pattern" role="presentation" width={348} height={311} className="pointer-events-none absolute right-0 top-0 h-auto w-[348px]" priority />

                <div className="grid flex-1 flex-col lg:grid-cols-[minmax(0,924fr),minmax(0,516fr)]">
                    <div className="flex flex-1 flex-col py-3 lg:pr-0">
                        <main className="flex flex-1 flex-col rounded-2xl bg-white p-6 ml-0 lg:ml-2.5 shadow-action ring-1 ring-[#E0E0E0]">{children}</main>
                    </div>

                    <div className="hidden flex-1 flex-col items-center justify-center px-12 lg:flex xl:px-24">
                        <div className="flex w-full max-w-md flex-col gap-8">
                            <div className={cn("transition-all duration-1000", isAnimating ? "translate-y-4 opacity-0" : "translate-y-0 opacity-100")}>
                                {/* Review Icon at top */}
                                <div className="mb-10 inline-flex h-20 w-20 items-center justify-center rounded-xl bg-white/20">
                                    <svg fill="currentColor" viewBox="0 0 33 23" xmlns="http://www.w3.org/2000/svg" className="h-5 w-auto text-white">
                                        <path d="M32.0011 4.7203 30.9745 0C23.5828.33861 18.459 3.41404 18.459 12.4583V22.8687H31.3725V9.78438H26.4818C26.4819 6.88236 28.3027 5.17551 32.0011 4.7203Z" />
                                        <path d="M13.5421 4.7203 12.5155 0C5.12386.33861 0 3.41413 0 12.4584V22.8687H12.914V9.78438H8.02029C8.02029 6.88236 9.84111 5.17551 13.5421 4.7203Z" />
                                    </svg>
                                </div>

                                <p className="text-xl font-medium tracking-[-0.01em] text-neutral-0 text-shadow">{currentReview.body}</p>

                                <div className="mt-8 flex w-max items-center gap-3 rounded-full bg-primary-normal/30 py-2.5 pl-3 pr-6">
                                    <Image src={currentReview.avatarSrc} alt="" width={48} height={48} className="size-12 shrink-0 overflow-hidden rounded-full bg-[#A7CDAE] shadow-lg" />
                                    <div>
                                        <div className="text-landing-label-l font-semibold text-white text-shadow-lg">{currentReview.author}</div>
                                        <div className="text-landing-paragraph-sm font-medium text-white text-shadow-lg">{currentReview.role}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </QueryClientProvider>
    )
}
